import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Model from './Model';
import ErrorPage from './ErrorPage';
import NotFound from './notFound';
import RedirectToChrome from './RedirectToChrome';

const router = createBrowserRouter([
  {
    path: "/model/:model",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/model/:model/redirect-to-chrome",
    element: <RedirectToChrome />,
  },
  {
    path: "/model/:model/view",
    element: <Model />,
  },
  {
    path: "/model/:model/view/:art",
    element: <Model />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
