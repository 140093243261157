import "@google/model-viewer";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "./config.ts";
import { RWebShare } from "react-web-share";
import { Share, ShoppingCart } from "lucide-react";

function Model() {
  const modelViewer = useRef(null);
  const errorViewer = useRef(null);
  const navigate = useNavigate();
  const { model } = useParams();
  const [res, setRes] = useState({});
  const [showActions, setShowActions] = useState(false);
  const [placement, setPlacement] = useState("floor"); //wall;

  useEffect(() => {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    ) {
      return navigate(`/model/${model}`);
    }
  }, [model, navigate]);

  useEffect(() => {
    if (!modelViewer.current) return;
    modelViewer.current.addEventListener("load", (p) => {

      console.log("text", modelViewer.current.geometry.computeBoundingBox());

      if (modelViewer.current.canActivateAR) {
      } else {
        alert("WebXR not available");
      }
      modelViewer.current.addEventListener("ar-status", handleWebARState);
    });
  }, [modelViewer]);

  const handleWebARState = (event) => {
    console.log("event.detail.status");
    console.log(event.detail.status);
    switch (event.detail.status) {
      case "not-presenting":
        setShowActions(true);
        break;
      case "session-started":
        setShowActions(true);
        break;
      case "object-placed":
        setShowActions(true);
        break;
      case "failed":
        setShowActions(false);
        break;
      default:
        console.warn(`Unexpected WebXR Status: ${event.detail.status}`);
        setShowActions(false);
        break;
    }
  };

  const loadModel = async () => {
    if (model.includes("model-")) {
      const modelLink = model.replace("model-", "");
      const iosModelLink = modelLink.replace(".glb", ".usdz");
      let ModelPoster = modelLink.replace(".glb", ".png");
      ModelPoster = modelLink.replace(".obj", ".png");
      setRes({
        model: `${window.location.protocol}//${window.location.host}/models/${modelLink}`,
        iosmodel: `${window.location.protocol}//${window.location.host}/models/${iosModelLink}`,
        image: `${window.location.protocol}//${window.location.host}/models/${ModelPoster}`,
        url: "https://the-creatiiives.myshopify.com/products/the-3p-fulfilled-snowboard",
      });
      if (modelLink.includes("back") || modelLink.includes("tableau")) {
        setPlacement("wall");
      }
    } else {
      try {
        const res = await axios
          .get(`/api/3d-models/single/${model}`)
          .catch((error) => {
            console.log(error);
          });
        if ("data" in res) {
          setRes(res?.data?.model);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    loadModel();
  }, []);

  return (
    <div className="mobile-view overflow-hidden">
      <div className="w-screen h-full model-preview">
        <div className="h-full w-screen flex bg-purple-950 flex-col justify-around items-center">
          <a
            rel="noreferrer"
            href={res.url}
            title="The Creatiiives"
            target="_blank"
          >
            <img
              className="top-0 left-1/3 h-10 w-auto object-contain m-2 max-w-[140px]"
              src="/client-logo.png"
              alt="provided by AR Display"
            />
          </a>
          <img
            className="w-[85%] h-auto max-w-xl m-auto object-contain rounded-lg"
            src={"/bridge-img.gif"}
            alt="model"
          />
          <button
            onClick={() => {
              document.getElementById("ARButtun").click();
            }}
            className="mb-6 bg-slate-400 px-3 py-2 rounded-full text-white text-sm flex justify-between items-center"
          >
            <img className="w-8 h-8 mr-1" src="/ar.png" alt="ar" />
            View in your space
          </button>
          <div className="bg-slate-400 w-full flex justify-center items-center">
            <span className="text-white text-sm font-light">Powered by</span>
            <a
              rel="noreferrer"
              href="https://ardisplay.io/"
              title="AR Display"
              target="_blank"
            >
              <img
                className="top-0 left-1/3 h-10 w-auto object-contain m-2"
                src="/logo.png"
                alt="provided by AR Display"
              />
            </a>
          </div>
        </div>
        <div className="h-screen w-screen">
          <div
            className="absolute top-0 left-0 right-0 bottom-0 z-50 bg-white text-black h-full w-full hidden"
            ref={errorViewer}
            id="error"
          >
            Can't display AR
          </div>
          <model-viewer
            ref={modelViewer}
            id="model-viewer"
            // alt="art"
            src={res.model}
            // ios-src={res.iosmodel}
            ar
            ar-modes="webxr scene-viewer quick-look"
            ar-placement={placement}
            // poster={res.image}
            camera-controls
            // exposure="1"
            shadow-intensity="1"
            tone-mapping="neutral"
            // shadow-softness="1"
            // ar-scale="auto"
          >
            <button id="ARButtun" slot="ar-button"></button>
            {showActions ? (
              <>
                <div className="absolute top-1 left-0 right-0 flex justify-center items-center">
                  <a
                    rel="noreferrer"
                    href={res.url}
                    title="The Creatiiives"
                    target="_blank"
                  >
                    <img
                      className="top-0 left-1/3 h-10 w-auto object-contain m-2 max-w-[140px]"
                      src="/client-logo.png"
                      alt="provided by AR Display"
                    />
                  </a>
                </div>
                <div className="absolute bottom-5 right-0 left-0 px-3 z-50 text-black flex justify-between items-center gap-3">
                  <a
                    className="rounded-full bg-[#3b37ff] p-3 shadow-md"
                    href="https://the-creatiiives.myshopify.com/cart/45208601231648:1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ShoppingCart className="text-white" />
                  </a>
                  <RWebShare
                    data={{
                      text: res.title,
                      url: window.location.href,
                      title: "AR Display",
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <a
                      className="rounded-full bg-[#9b50b4] p-3 shadow-md"
                      title="share"
                    >
                      <Share className="text-white" />
                    </a>
                  </RWebShare>
                </div>
              </>
            ) : null}
          </model-viewer>
        </div>
      </div>
    </div>
  );
}

export default Model;
